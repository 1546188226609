import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue'),
  meta: {
      title: '总览'
  },
  children: [
    {
        path: '/',
        component: () => import('../views/overViewPage.vue'),
        name: "overViewPage",
        meta: {
            title: '总览'
        }
    },
    {
      path: '/controlPage',
      name: 'controlPage',
      meta: {
        title: '指令'
      },
      component: () => import('../views/controlPage.vue')
    },
    {
      path: '/devicePage',
      name: 'devicePage',
      meta: {
        title: '设备'
      },
      component: () => import('../views/devicePage.vue')
    },
    {
      path: '/partsPage',
      name: 'partsPage',
      meta: {
        title: '部件'
      },
      component: () => import('../views/partsPage.vue')
    }
  ]
},
{
  path: '/login',
  name: 'login',
  meta: {
    title: '登录'
  },
  component: () => import('../views/login.vue')
},
{
  path: '/error',
  name: 'error',
  meta: {
    title: '错误'
  },
  component: () => import('../views/error.vue')
}]


const router = new VueRouter({
  mode: 'history',  // 使用历史模式
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to,from)
  next()
})

router.afterEach((to, from) => {
  console.log(from)
  if (to.meta.title) {
      document.title = to.meta.title
  }
})

export default router